import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Grid,
  Box,
  Alert,
  CircularProgress,
  Divider,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';

const cylinderTypes = [
  { value: 'single', label: 'Single Acting' },
  { value: 'double', label: 'Double Acting' },
];

function Cylinder() {
  const [values, setValues] = useState({
    cylinderType: 'double',
    boreDiameter: '',
    rodDiameter: '',
    stroke: '',
    pressure: '',
  });
  const [result, setResult] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleChange = (field) => (event) => {
    setValues(prev => ({
      ...prev,
      [field]: event.target.value
    }));
  };

  const calculateCylinder = () => {
    const { cylinderType, boreDiameter, rodDiameter, stroke, pressure } = values;
    
    if (!boreDiameter || !stroke || !pressure) {
      setError('Please fill in all required fields');
      return;
    }

    if (cylinderType === 'double' && !rodDiameter) {
      setError('Rod diameter is required for double-acting cylinders');
      return;
    }

    setLoading(true);
    setError('');

    try {
      const boreDiameterNum = parseFloat(boreDiameter);
      const rodDiameterNum = parseFloat(rodDiameter);
      const strokeNum = parseFloat(stroke);
      const pressureNum = parseFloat(pressure);

      if (isNaN(boreDiameterNum) || isNaN(strokeNum) || isNaN(pressureNum)) {
        throw new Error('Please enter valid numbers');
      }

      // Cylinder calculations
      // Area = π * (diameter/2)²
      // Force = Pressure * Area
      // Volume = Area * Stroke
      // Where:
      // Area = mm²
      // Force = N
      // Volume = mm³
      // Pressure = bar
      // Stroke = mm

      const boreArea = Math.PI * Math.pow(boreDiameterNum / 2, 2);
      const rodArea = cylinderType === 'double' ? Math.PI * Math.pow(rodDiameterNum / 2, 2) : 0;
      const effectiveArea = boreArea - (cylinderType === 'double' ? rodArea : 0);
      
      const force = pressureNum * effectiveArea;
      const volume = boreArea * strokeNum;

      setResult({
        force: force.toFixed(2),
        volume: volume.toFixed(2),
        boreArea: boreArea.toFixed(2),
        effectiveArea: effectiveArea.toFixed(2),
      });
    } catch (err) {
      setError(err.message);
      setResult(null);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" component="h2" gutterBottom>
          Cylinder Calculator
        </Typography>
        
        <Typography variant="body1" color="text.secondary" paragraph>
          Calculate cylinder force, volume, and area based on specifications.
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel>Cylinder Type</InputLabel>
              <Select
                value={values.cylinderType}
                onChange={handleChange('cylinderType')}
                label="Cylinder Type"
              >
                {cylinderTypes.map((type) => (
                  <MenuItem key={type.value} value={type.value}>
                    {type.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Bore Diameter (mm)"
              type="number"
              value={values.boreDiameter}
              onChange={handleChange('boreDiameter')}
              InputProps={{
                inputProps: { min: 0 }
              }}
            />
          </Grid>

          {values.cylinderType === 'double' && (
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Rod Diameter (mm)"
                type="number"
                value={values.rodDiameter}
                onChange={handleChange('rodDiameter')}
                InputProps={{
                  inputProps: { min: 0 }
                }}
              />
            </Grid>
          )}

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Stroke (mm)"
              type="number"
              value={values.stroke}
              onChange={handleChange('stroke')}
              InputProps={{
                inputProps: { min: 0 }
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Pressure (bar)"
              type="number"
              value={values.pressure}
              onChange={handleChange('pressure')}
              InputProps={{
                inputProps: { min: 0 }
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={calculateCylinder}
              fullWidth
              size="large"
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : 'Calculate'}
            </Button>
          </Grid>

          {error && (
            <Grid item xs={12}>
              <Alert severity="error">{error}</Alert>
            </Grid>
          )}

          {result !== null && (
            <Grid item xs={12}>
              <Box sx={{ mt: 2, p: 2, bgcolor: 'background.paper', borderRadius: 1 }}>
                <Typography variant="h6" gutterBottom>
                  Results:
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="subtitle1">Force:</Typography>
                    <Typography variant="h5" color="primary">
                      {result.force} N
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="subtitle1">Volume:</Typography>
                    <Typography variant="h5" color="primary">
                      {result.volume} mm³
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="subtitle1">Bore Area:</Typography>
                    <Typography variant="h5" color="primary">
                      {result.boreArea} mm²
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="subtitle1">Effective Area:</Typography>
                    <Typography variant="h5" color="primary">
                      {result.effectiveArea} mm²
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          )}

          <Grid item xs={12}>
            <Divider sx={{ my: 2 }} />
            <Typography variant="body2" color="text.secondary">
              Formulas:
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Area = π * (diameter/2)²
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Force = Pressure * Area
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Volume = Area * Stroke
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Where:
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Area = mm²
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Force = N
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Volume = mm³
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Pressure = bar
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Stroke = mm
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default Cylinder; 