import React from 'react';
import { Container, Grid, Typography, Box } from '@mui/material';
import UnitConverter from './components/UnitConverter';
import FAD from './components/FAD';
import SandBlasting from './components/SandBlasting';
import Cylinder from './components/Cylinder';
import PressureDrop from './components/PressureDrop';
import BeltSize from './components/BeltSize';

function App() {
  return (
    <Container maxWidth="lg">
      <Box sx={{ mb: 4 }}>
        <Typography variant="h3" component="h1" gutterBottom>
          DT Converter
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          Industrial unit conversion and calculation tools
        </Typography>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <UnitConverter />
        </Grid>
        <Grid item xs={12} md={6}>
          <FAD />
        </Grid>
        <Grid item xs={12} md={6}>
          <SandBlasting />
        </Grid>
        <Grid item xs={12} md={6}>
          <Cylinder />
        </Grid>
        <Grid item xs={12} md={6}>
          <PressureDrop />
        </Grid>
        <Grid item xs={12}>
          <BeltSize />
        </Grid>
      </Grid>
    </Container>
  );
}

export default App; 