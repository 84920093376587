import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Grid,
  Box,
  Alert,
  CircularProgress,
  Divider,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';

const pipeMaterials = [
  { value: 'steel', label: 'Steel' },
  { value: 'copper', label: 'Copper' },
  { value: 'aluminum', label: 'Aluminum' },
  { value: 'plastic', label: 'Plastic' },
];

function PressureDrop() {
  const [values, setValues] = useState({
    pipeMaterial: 'steel',
    pipeLength: '',
    pipeDiameter: '',
    flowRate: '',
    pressure: '',
    temperature: '',
  });
  const [result, setResult] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleChange = (field) => (event) => {
    setValues(prev => ({
      ...prev,
      [field]: event.target.value
    }));
  };

  const calculatePressureDrop = () => {
    const { pipeMaterial, pipeLength, pipeDiameter, flowRate, pressure, temperature } = values;
    
    if (!pipeLength || !pipeDiameter || !flowRate || !pressure || !temperature) {
      setError('Please fill in all fields');
      return;
    }

    setLoading(true);
    setError('');

    try {
      const pipeLengthNum = parseFloat(pipeLength);
      const pipeDiameterNum = parseFloat(pipeDiameter);
      const flowRateNum = parseFloat(flowRate);
      const pressureNum = parseFloat(pressure);
      const temperatureNum = parseFloat(temperature);

      if (isNaN(pipeLengthNum) || isNaN(pipeDiameterNum) || isNaN(flowRateNum) || 
          isNaN(pressureNum) || isNaN(temperatureNum)) {
        throw new Error('Please enter valid numbers');
      }

      // Pressure drop calculation using Darcy-Weisbach equation
      // ΔP = f * (L/D) * (ρ * v²/2)
      // Where:
      // ΔP = Pressure drop (bar)
      // f = Friction factor (dimensionless)
      // L = Pipe length (m)
      // D = Pipe diameter (m)
      // ρ = Air density (kg/m³)
      // v = Flow velocity (m/s)

      // Get material roughness (ε) in meters
      const roughness = {
        steel: 0.000045,
        copper: 0.0000015,
        aluminum: 0.0000015,
        plastic: 0.0000015,
      }[pipeMaterial];

      // Calculate air density using ideal gas law
      const R = 287.05; // Gas constant for air (J/kg·K)
      const T = temperatureNum + 273.15; // Convert to Kelvin
      const P = pressureNum * 100000; // Convert bar to Pa
      const density = P / (R * T);

      // Calculate flow velocity
      const area = Math.PI * Math.pow(pipeDiameterNum / 1000, 2); // Convert mm to m
      const velocity = (flowRateNum / 60) / area; // Convert l/min to m³/s

      // Calculate Reynolds number
      const viscosity = 1.789e-5; // Dynamic viscosity of air at 20°C
      const Re = (density * velocity * pipeDiameterNum / 1000) / viscosity;

      // Calculate friction factor using Colebrook-White equation
      let f = 0.02; // Initial guess
      let fNew;
      let tolerance = 1e-6;
      let iterations = 0;
      const maxIterations = 100;

      do {
        fNew = 1 / Math.pow(-2 * Math.log10(roughness / (3.71 * pipeDiameterNum / 1000) + 2.51 / (Re * Math.sqrt(f))), 2);
        if (Math.abs(fNew - f) < tolerance) break;
        f = fNew;
        iterations++;
      } while (iterations < maxIterations);

      // Calculate pressure drop
      const pressureDrop = f * (pipeLengthNum / (pipeDiameterNum / 1000)) * (density * Math.pow(velocity, 2) / 2);
      const pressureDropBar = pressureDrop / 100000; // Convert Pa to bar

      setResult(pressureDropBar.toFixed(4));
    } catch (err) {
      setError(err.message);
      setResult(null);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" component="h2" gutterBottom>
          Pressure Drop Calculator
        </Typography>
        
        <Typography variant="body1" color="text.secondary" paragraph>
          Calculate pressure drop in pneumatic systems using the Darcy-Weisbach equation.
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel>Pipe Material</InputLabel>
              <Select
                value={values.pipeMaterial}
                onChange={handleChange('pipeMaterial')}
                label="Pipe Material"
              >
                {pipeMaterials.map((material) => (
                  <MenuItem key={material.value} value={material.value}>
                    {material.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Pipe Length (m)"
              type="number"
              value={values.pipeLength}
              onChange={handleChange('pipeLength')}
              InputProps={{
                inputProps: { min: 0 }
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Pipe Diameter (mm)"
              type="number"
              value={values.pipeDiameter}
              onChange={handleChange('pipeDiameter')}
              InputProps={{
                inputProps: { min: 0 }
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Flow Rate (l/min)"
              type="number"
              value={values.flowRate}
              onChange={handleChange('flowRate')}
              InputProps={{
                inputProps: { min: 0 }
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Pressure (bar)"
              type="number"
              value={values.pressure}
              onChange={handleChange('pressure')}
              InputProps={{
                inputProps: { min: 0 }
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Temperature (°C)"
              type="number"
              value={values.temperature}
              onChange={handleChange('temperature')}
              InputProps={{
                inputProps: { min: -273.15 }
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={calculatePressureDrop}
              fullWidth
              size="large"
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : 'Calculate Pressure Drop'}
            </Button>
          </Grid>

          {error && (
            <Grid item xs={12}>
              <Alert severity="error">{error}</Alert>
            </Grid>
          )}

          {result !== null && (
            <Grid item xs={12}>
              <Box sx={{ mt: 2, p: 2, bgcolor: 'background.paper', borderRadius: 1 }}>
                <Typography variant="h6" gutterBottom>
                  Pressure Drop:
                </Typography>
                <Typography variant="h4" color="primary">
                  {result} bar
                </Typography>
              </Box>
            </Grid>
          )}

          <Grid item xs={12}>
            <Divider sx={{ my: 2 }} />
            <Typography variant="body2" color="text.secondary">
              Formula: ΔP = f * (L/D) * (ρ * v²/2)
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Where:
            </Typography>
            <Typography variant="body2" color="text.secondary">
              ΔP = Pressure drop (bar)
            </Typography>
            <Typography variant="body2" color="text.secondary">
              f = Friction factor (dimensionless)
            </Typography>
            <Typography variant="body2" color="text.secondary">
              L = Pipe length (m)
            </Typography>
            <Typography variant="body2" color="text.secondary">
              D = Pipe diameter (m)
            </Typography>
            <Typography variant="body2" color="text.secondary">
              ρ = Air density (kg/m³)
            </Typography>
            <Typography variant="body2" color="text.secondary">
              v = Flow velocity (m/s)
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default PressureDrop; 