// Length conversions
export const lengthConversions = {
  mm: {
    cm: (value) => value / 10,
    m: (value) => value / 1000,
    km: (value) => value / 1000000,
    in: (value) => value / 25.4,
    ft: (value) => value / 304.8,
    yd: (value) => value / 914.4,
    mi: (value) => value / 1609344,
  },
  cm: {
    mm: (value) => value * 10,
    m: (value) => value / 100,
    km: (value) => value / 100000,
    in: (value) => value / 2.54,
    ft: (value) => value / 30.48,
    yd: (value) => value / 91.44,
    mi: (value) => value / 160934.4,
  },
  // Add more length conversions...
};

// Pressure conversions
export const pressureConversions = {
  bar: {
    psi: (value) => value * 14.5038,
    kpa: (value) => value * 100,
    mpa: (value) => value / 10,
  },
  psi: {
    bar: (value) => value / 14.5038,
    kpa: (value) => value * 6.89476,
    mpa: (value) => value * 0.00689476,
  },
  // Add more pressure conversions...
};

// Temperature conversions
export const temperatureConversions = {
  c: {
    f: (value) => (value * 9/5) + 32,
    k: (value) => value + 273.15,
  },
  f: {
    c: (value) => (value - 32) * 5/9,
    k: (value) => (value - 32) * 5/9 + 273.15,
  },
  k: {
    c: (value) => value - 273.15,
    f: (value) => (value - 273.15) * 9/5 + 32,
  },
};

// Flow rate conversions
export const flowConversions = {
  lpm: {
    m3h: (value) => value * 0.06,
    cfm: (value) => value * 0.0353147,
    gpm: (value) => value * 0.264172,
    m3s: (value) => value * 0.0000166667,
    lps: (value) => value * 0.0166667,
    mls: (value) => value * 16.6667,
    in3s: (value) => value * 1.01706,
    ft3h: (value) => value * 2.11888,
    galh: (value) => value * 15.8503,
    bblh: (value) => value * 0.377389,
  },
  m3h: {
    lpm: (value) => value * 16.6667,
    cfm: (value) => value * 0.588578,
    gpm: (value) => value * 4.40287,
    m3s: (value) => value * 0.000277778,
    lps: (value) => value * 0.277778,
    mls: (value) => value * 277.778,
    in3s: (value) => value * 16.951,
    ft3h: (value) => value * 35.3147,
    galh: (value) => value * 264.172,
    bblh: (value) => value * 6.28981,
  },
  cfm: {
    lpm: (value) => value * 28.3168,
    m3h: (value) => value * 1.69901,
    gpm: (value) => value * 7.48052,
    m3s: (value) => value * 0.000471947,
    lps: (value) => value * 0.471947,
    mls: (value) => value * 471.947,
    in3s: (value) => value * 28.8,
    ft3h: (value) => value * 60,
    galh: (value) => value * 448.831,
    bblh: (value) => value * 10.6865,
  },
  gpm: {
    lpm: (value) => value * 3.78541,
    m3h: (value) => value * 0.227125,
    cfm: (value) => value * 0.133681,
    m3s: (value) => value * 0.0000630902,
    lps: (value) => value * 0.0630902,
    mls: (value) => value * 63.0902,
    in3s: (value) => value * 3.85,
    ft3h: (value) => value * 8.02083,
    galh: (value) => value * 60,
    bblh: (value) => value * 1.42857,
  },
  m3s: {
    lpm: (value) => value * 60000,
    m3h: (value) => value * 3600,
    cfm: (value) => value * 2118.88,
    gpm: (value) => value * 15850.3,
    lps: (value) => value * 1000,
    mls: (value) => value * 1000000,
    in3s: (value) => value * 61023.7,
    ft3h: (value) => value * 127132.8,
    galh: (value) => value * 951019.4,
    bblh: (value) => value * 22643.3,
  },
  lps: {
    lpm: (value) => value * 60,
    m3h: (value) => value * 3.6,
    cfm: (value) => value * 2.11888,
    gpm: (value) => value * 15.8503,
    m3s: (value) => value * 0.001,
    mls: (value) => value * 1000,
    in3s: (value) => value * 61.0237,
    ft3h: (value) => value * 127.133,
    galh: (value) => value * 951.019,
    bblh: (value) => value * 22.6433,
  },
  mls: {
    lpm: (value) => value * 0.06,
    m3h: (value) => value * 0.0036,
    cfm: (value) => value * 0.00211888,
    gpm: (value) => value * 0.0158503,
    m3s: (value) => value * 0.000001,
    lps: (value) => value * 0.001,
    in3s: (value) => value * 0.0610237,
    ft3h: (value) => value * 0.127133,
    galh: (value) => value * 0.951019,
    bblh: (value) => value * 0.0226433,
  },
  in3s: {
    lpm: (value) => value * 0.983223,
    m3h: (value) => value * 0.0589934,
    cfm: (value) => value * 0.0347222,
    gpm: (value) => value * 0.25974,
    m3s: (value) => value * 0.0000163871,
    lps: (value) => value * 0.0163871,
    mls: (value) => value * 16.3871,
    ft3h: (value) => value * 2.08333,
    galh: (value) => value * 15.5844,
    bblh: (value) => value * 0.371057,
  },
  ft3h: {
    lpm: (value) => value * 0.471947,
    m3h: (value) => value * 0.0283168,
    cfm: (value) => value * 0.0166667,
    gpm: (value) => value * 0.124676,
    m3s: (value) => value * 0.00000786579,
    lps: (value) => value * 0.00786579,
    mls: (value) => value * 7.86579,
    in3s: (value) => value * 0.48,
    galh: (value) => value * 7.48052,
    bblh: (value) => value * 0.178107,
  },
  galh: {
    lpm: (value) => value * 0.0630902,
    m3h: (value) => value * 0.00378541,
    cfm: (value) => value * 0.00222801,
    gpm: (value) => value * 0.0166667,
    m3s: (value) => value * 0.0000010515,
    lps: (value) => value * 0.0010515,
    mls: (value) => value * 1.0515,
    in3s: (value) => value * 0.0641667,
    ft3h: (value) => value * 0.133681,
    bblh: (value) => value * 0.0238095,
  },
  bblh: {
    lpm: (value) => value * 2.64979,
    m3h: (value) => value * 0.158987,
    cfm: (value) => value * 0.0935764,
    gpm: (value) => value * 0.7,
    m3s: (value) => value * 0.0000441631,
    lps: (value) => value * 0.0441631,
    mls: (value) => value * 44.1631,
    in3s: (value) => value * 2.695,
    ft3h: (value) => value * 5.61458,
    galh: (value) => value * 42,
  },
};

// Main conversion function
export const convert = (value, fromUnit, toUnit, category) => {
  const conversions = {
    length: lengthConversions,
    pressure: pressureConversions,
    temperature: temperatureConversions,
    flow: flowConversions,
  };

  if (!conversions[category]?.[fromUnit]?.[toUnit]) {
    throw new Error(`Conversion from ${fromUnit} to ${toUnit} not supported`);
  }

  return conversions[category][fromUnit][toUnit](parseFloat(value));
};

// Format result based on unit type
export const formatResult = (value, unit, category) => {
  const numValue = parseFloat(value);
  
  // Format based on unit type
  if (unit === 'c' || unit === 'f') {
    return numValue.toFixed(1);
  } else if (unit === 'k') {
    return numValue.toFixed(2);
  } else if (['bar', 'psi', 'kpa', 'mpa'].includes(unit)) {
    return numValue.toFixed(2);
  } else if (category === 'flow') {
    return numValue.toFixed(2);
  } else {
    return numValue.toFixed(2);
  }
}; 