import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Grid,
  Box,
  Alert,
  CircularProgress,
  Divider,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';

const nozzleTypes = [
  { value: 'venturi', label: 'Venturi Nozzle' },
  { value: 'straight', label: 'Straight Bore Nozzle' },
];

const abrasiveTypes = [
  { value: 'garnet', label: 'Garnet' },
  { value: 'aluminum_oxide', label: 'Aluminum Oxide' },
  { value: 'steel_grit', label: 'Steel Grit' },
  { value: 'glass_beads', label: 'Glass Beads' },
];

function SandBlasting() {
  const [values, setValues] = useState({
    nozzleType: 'venturi',
    abrasiveType: 'garnet',
    nozzleDiameter: '',
    pressure: '',
    airFlow: '',
    abrasiveFlow: '',
  });
  const [result, setResult] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleChange = (field) => (event) => {
    setValues(prev => ({
      ...prev,
      [field]: event.target.value
    }));
  };

  const calculateSandBlasting = () => {
    const { nozzleType, abrasiveType, nozzleDiameter, pressure, airFlow, abrasiveFlow } = values;
    
    if (!nozzleDiameter || !pressure || !airFlow || !abrasiveFlow) {
      setError('Please fill in all fields');
      return;
    }

    setLoading(true);
    setError('');

    try {
      const nozzleDiameterNum = parseFloat(nozzleDiameter);
      const pressureNum = parseFloat(pressure);
      const airFlowNum = parseFloat(airFlow);
      const abrasiveFlowNum = parseFloat(abrasiveFlow);

      if (isNaN(nozzleDiameterNum) || isNaN(pressureNum) || isNaN(airFlowNum) || isNaN(abrasiveFlowNum)) {
        throw new Error('Please enter valid numbers');
      }

      // Sandblasting efficiency calculation
      // Efficiency = (Abrasive Flow Rate * Air Flow Rate) / (Nozzle Area * Pressure)
      // Where:
      // Efficiency = Blasting efficiency (kg/m²·min)
      // Abrasive Flow Rate = kg/min
      // Air Flow Rate = m³/min
      // Nozzle Area = π * (diameter/2)²
      // Pressure = bar

      const nozzleArea = Math.PI * Math.pow(nozzleDiameterNum / 2, 2);
      const efficiency = (abrasiveFlowNum * airFlowNum) / (nozzleArea * pressureNum);
      
      setResult(efficiency.toFixed(2));
    } catch (err) {
      setError(err.message);
      setResult(null);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" component="h2" gutterBottom>
          Sandblasting Calculator
        </Typography>
        
        <Typography variant="body1" color="text.secondary" paragraph>
          Calculate sandblasting efficiency and parameters based on equipment specifications.
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel>Nozzle Type</InputLabel>
              <Select
                value={values.nozzleType}
                onChange={handleChange('nozzleType')}
                label="Nozzle Type"
              >
                {nozzleTypes.map((type) => (
                  <MenuItem key={type.value} value={type.value}>
                    {type.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel>Abrasive Type</InputLabel>
              <Select
                value={values.abrasiveType}
                onChange={handleChange('abrasiveType')}
                label="Abrasive Type"
              >
                {abrasiveTypes.map((type) => (
                  <MenuItem key={type.value} value={type.value}>
                    {type.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Nozzle Diameter (mm)"
              type="number"
              value={values.nozzleDiameter}
              onChange={handleChange('nozzleDiameter')}
              InputProps={{
                inputProps: { min: 0 }
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Pressure (bar)"
              type="number"
              value={values.pressure}
              onChange={handleChange('pressure')}
              InputProps={{
                inputProps: { min: 0 }
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Air Flow Rate (m³/min)"
              type="number"
              value={values.airFlow}
              onChange={handleChange('airFlow')}
              InputProps={{
                inputProps: { min: 0 }
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Abrasive Flow Rate (kg/min)"
              type="number"
              value={values.abrasiveFlow}
              onChange={handleChange('abrasiveFlow')}
              InputProps={{
                inputProps: { min: 0 }
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={calculateSandBlasting}
              fullWidth
              size="large"
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : 'Calculate Efficiency'}
            </Button>
          </Grid>

          {error && (
            <Grid item xs={12}>
              <Alert severity="error">{error}</Alert>
            </Grid>
          )}

          {result !== null && (
            <Grid item xs={12}>
              <Box sx={{ mt: 2, p: 2, bgcolor: 'background.paper', borderRadius: 1 }}>
                <Typography variant="h6" gutterBottom>
                  Blasting Efficiency:
                </Typography>
                <Typography variant="h4" color="primary">
                  {result} kg/m²·min
                </Typography>
              </Box>
            </Grid>
          )}

          <Grid item xs={12}>
            <Divider sx={{ my: 2 }} />
            <Typography variant="body2" color="text.secondary">
              Formula: Efficiency = (Abrasive Flow Rate * Air Flow Rate) / (Nozzle Area * Pressure)
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Where:
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Efficiency = Blasting efficiency (kg/m²·min)
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Abrasive Flow Rate = kg/min
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Air Flow Rate = m³/min
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Nozzle Area = π * (diameter/2)²
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Pressure = bar
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default SandBlasting; 