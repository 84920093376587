import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Grid,
  Box,
  Alert,
  CircularProgress,
  Divider,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Tooltip,
} from '@mui/material';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { convert, formatResult } from '../utils/conversions';

const categories = [
  { value: 'area', label: 'Area' },
  { value: 'energy', label: 'Energy' },
  { value: 'flow', label: 'Flow Rate' },
  { value: 'force', label: 'Force' },
  { value: 'length', label: 'Length' },
  { value: 'mass', label: 'Mass' },
  { value: 'power', label: 'Power' },
  { value: 'pressure', label: 'Pressure' },
  { value: 'speed', label: 'Speed' },
  { value: 'temperature', label: 'Temperature' },
  { value: 'torque', label: 'Torque' },
  { value: 'volume', label: 'Volume' },
];

const units = {
  area: [
    { value: 'ac', label: 'Acres (ac)' },
    { value: 'cm2', label: 'Square Centimeters (cm²)' },
    { value: 'ft2', label: 'Square Feet (ft²)' },
    { value: 'in2', label: 'Square Inches (in²)' },
    { value: 'km2', label: 'Square Kilometers (km²)' },
    { value: 'm2', label: 'Square Meters (m²)' },
    { value: 'mm2', label: 'Square Millimeters (mm²)' },
    { value: 'yd2', label: 'Square Yards (yd²)' },
  ],
  energy: [
    { value: 'cal', label: 'Calories (cal)' },
    { value: 'j', label: 'Joules (J)' },
    { value: 'kcal', label: 'Kilocalories (kcal)' },
    { value: 'kj', label: 'Kilojoules (kJ)' },
    { value: 'kwh', label: 'Kilowatt Hours (kWh)' },
    { value: 'mj', label: 'Megajoules (MJ)' },
  ],
  flow: [
    { value: 'bblh', label: 'Barrels per Hour (bbl/h)' },
    { value: 'cfm', label: 'Cubic Feet per Minute (CFM)' },
    { value: 'ft3h', label: 'Cubic Feet per Hour (ft³/h)' },
    { value: 'galh', label: 'Gallons per Hour (gal/h)' },
    { value: 'gpm', label: 'Gallons per Minute (GPM)' },
    { value: 'in3s', label: 'Cubic Inches per Second (in³/s)' },
    { value: 'lpm', label: 'Liters per Minute (L/min)' },
    { value: 'lps', label: 'Liters per Second (L/s)' },
    { value: 'm3h', label: 'Cubic Meters per Hour (m³/h)' },
    { value: 'm3s', label: 'Cubic Meters per Second (m³/s)' },
    { value: 'mls', label: 'Milliliters per Second (mL/s)' },
  ],
  force: [
    { value: 'kn', label: 'Kilonewtons (kN)' },
    { value: 'lbf', label: 'Pound-force (lbf)' },
    { value: 'mn', label: 'Meganewtons (MN)' },
    { value: 'n', label: 'Newtons (N)' },
  ],
  length: [
    { value: 'cm', label: 'Centimeters (cm)' },
    { value: 'ft', label: 'Feet (ft)' },
    { value: 'in', label: 'Inches (in)' },
    { value: 'km', label: 'Kilometers (km)' },
    { value: 'm', label: 'Meters (m)' },
    { value: 'mi', label: 'Miles (mi)' },
    { value: 'mm', label: 'Millimeters (mm)' },
    { value: 'yd', label: 'Yards (yd)' },
  ],
  mass: [
    { value: 'g', label: 'Grams (g)' },
    { value: 'kg', label: 'Kilograms (kg)' },
    { value: 'lb', label: 'Pounds (lb)' },
    { value: 'mg', label: 'Milligrams (mg)' },
    { value: 'oz', label: 'Ounces (oz)' },
    { value: 'st', label: 'Stone (st)' },
    { value: 't', label: 'Metric Tons (t)' },
  ],
  power: [
    { value: 'hp', label: 'Horsepower (hp)' },
    { value: 'kw', label: 'Kilowatts (kW)' },
    { value: 'mw', label: 'Megawatts (MW)' },
    { value: 'w', label: 'Watts (W)' },
  ],
  pressure: [
    { value: 'atm', label: 'Atmosphere (atm)' },
    { value: 'bar', label: 'Bar' },
    { value: 'kpa', label: 'Kilopascal (kPa)' },
    { value: 'mpa', label: 'Megapascal (MPa)' },
    { value: 'psi', label: 'PSI' },
    { value: 'torr', label: 'Torr' },
  ],
  speed: [
    { value: 'kmh', label: 'Kilometers per Hour (km/h)' },
    { value: 'knots', label: 'Knots' },
    { value: 'mph', label: 'Miles per Hour (mph)' },
    { value: 'mps', label: 'Meters per Second (m/s)' },
  ],
  temperature: [
    { value: 'c', label: 'Celsius (°C)' },
    { value: 'f', label: 'Fahrenheit (°F)' },
    { value: 'k', label: 'Kelvin (K)' },
  ],
  torque: [
    { value: 'ftlb', label: 'Foot Pounds (ft·lb)' },
    { value: 'inlb', label: 'Inch Pounds (in·lb)' },
    { value: 'nm', label: 'Newton Meters (N·m)' },
  ],
  volume: [
    { value: 'fl_oz', label: 'Fluid Ounces (fl oz)' },
    { value: 'gal', label: 'Gallons (gal)' },
    { value: 'l', label: 'Liters (L)' },
    { value: 'm3', label: 'Cubic Meters (m³)' },
    { value: 'ml', label: 'Milliliters (mL)' },
    { value: 'pt', label: 'Pints (pt)' },
    { value: 'qt', label: 'Quarts (qt)' },
  ],
};

function UnitConverter() {
  const [values, setValues] = useState({
    category: 'length',
    fromValue: '',
    fromUnit: '',
    toUnit: '',
  });
  const [result, setResult] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [history, setHistory] = useState([]);

  const handleChange = (field) => (event) => {
    setValues(prev => ({
      ...prev,
      [field]: event.target.value
    }));
  };

  const swapUnits = () => {
    setValues(prev => ({
      ...prev,
      fromUnit: prev.toUnit,
      toUnit: prev.fromUnit,
    }));
  };

  const handleConvert = () => {
    const { category, fromValue, fromUnit, toUnit } = values;
    
    if (!fromValue || !fromUnit || !toUnit) {
      setError('Please fill in all fields');
      return;
    }

    setLoading(true);
    setError('');

    try {
      const fromValueNum = parseFloat(fromValue);
      if (isNaN(fromValueNum)) {
        throw new Error('Please enter a valid number');
      }

      const convertedValue = convert(fromValueNum, fromUnit, toUnit, category);
      const formattedResult = formatResult(convertedValue, toUnit, category);

      // Add to history
      const historyItem = {
        timestamp: new Date().toISOString(),
        category,
        fromValue: fromValueNum,
        fromUnit,
        toValue: convertedValue,
        toUnit,
      };
      setHistory(prev => [historyItem, ...prev].slice(0, 5));

      setResult(formattedResult);
    } catch (err) {
      setError(err.message);
      setResult(null);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" component="h2" gutterBottom>
          Unit Converter
        </Typography>
        
        <Typography variant="body1" color="text.secondary" paragraph>
          Convert between various units across multiple categories.
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel>Category</InputLabel>
              <Select
                value={values.category}
                onChange={handleChange('category')}
                label="Category"
              >
                {categories.map((category) => (
                  <MenuItem key={category.value} value={category.value}>
                    {category.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Value"
              type="number"
              value={values.fromValue}
              onChange={handleChange('fromValue')}
              inputMode="decimal"
              pattern="[0-9]*[.,]?[0-9]*"
              InputProps={{
                inputProps: { 
                  min: 0,
                  step: "any",
                  inputMode: "decimal",
                  pattern: "[0-9]*[.,]?[0-9]*"
                }
              }}
            />
          </Grid>

          <Grid item xs={12} md={5}>
            <FormControl fullWidth>
              <InputLabel>From Unit</InputLabel>
              <Select
                value={values.fromUnit}
                onChange={handleChange('fromUnit')}
                label="From Unit"
              >
                {units[values.category]?.map((unit) => (
                  <MenuItem key={unit.value} value={unit.value}>
                    {unit.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Tooltip title="Swap Units">
              <IconButton onClick={swapUnits} color="primary">
                <SwapHorizIcon />
              </IconButton>
            </Tooltip>
          </Grid>

          <Grid item xs={12} md={5}>
            <FormControl fullWidth>
              <InputLabel>To Unit</InputLabel>
              <Select
                value={values.toUnit}
                onChange={handleChange('toUnit')}
                label="To Unit"
              >
                {units[values.category]?.map((unit) => (
                  <MenuItem key={unit.value} value={unit.value}>
                    {unit.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleConvert}
              fullWidth
              size="large"
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : 'Convert'}
            </Button>
          </Grid>

          {error && (
            <Grid item xs={12}>
              <Alert severity="error">{error}</Alert>
            </Grid>
          )}

          {result !== null && (
            <Grid item xs={12}>
              <Box sx={{ mt: 2, p: 2, bgcolor: 'background.paper', borderRadius: 1 }}>
                <Typography variant="h6" gutterBottom>
                  Result:
                </Typography>
                <Typography variant="h4" color="primary">
                  {result}
                </Typography>
              </Box>
            </Grid>
          )}

          {history.length > 0 && (
            <Grid item xs={12}>
              <Divider sx={{ my: 2 }} />
              <Typography variant="h6" gutterBottom>
                Recent Conversions:
              </Typography>
              <Box sx={{ mt: 2 }}>
                {history.map((item, index) => (
                  <Box key={index} sx={{ mb: 1 }}>
                    <Typography variant="body2" color="text.secondary">
                      {new Date(item.timestamp).toLocaleString()}
                    </Typography>
                    <Typography variant="body1">
                      {item.fromValue} {item.fromUnit} = {formatResult(item.toValue, item.toUnit, item.category)}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}

export default UnitConverter; 