import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Grid,
  Box,
  Alert,
  CircularProgress,
  Divider,
} from '@mui/material';

function FAD() {
  const [values, setValues] = useState({
    pressure: '',
    temperature: '',
    humidity: '',
    flowRate: '',
  });
  const [result, setResult] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleChange = (field) => (event) => {
    setValues(prev => ({
      ...prev,
      [field]: event.target.value
    }));
  };

  const calculateFAD = () => {
    const { pressure, temperature, humidity, flowRate } = values;
    
    if (!pressure || !temperature || !humidity || !flowRate) {
      setError('Please fill in all fields');
      return;
    }

    setLoading(true);
    setError('');

    try {
      const pressureNum = parseFloat(pressure);
      const temperatureNum = parseFloat(temperature);
      const humidityNum = parseFloat(humidity);
      const flowRateNum = parseFloat(flowRate);

      if (isNaN(pressureNum) || isNaN(temperatureNum) || isNaN(humidityNum) || isNaN(flowRateNum)) {
        throw new Error('Please enter valid numbers');
      }

      // FAD calculation formula
      // FAD = Q * (P1/P2) * (T2/T1) * (1 - RH/100)
      // Where:
      // FAD = Free Air Delivery (l/min)
      // Q = Measured flow rate (l/min)
      // P1 = Atmospheric pressure (bar)
      // P2 = Compressed air pressure (bar)
      // T1 = Atmospheric temperature (K)
      // T2 = Compressed air temperature (K)
      // RH = Relative humidity (%)

      const P1 = 1.01325; // Standard atmospheric pressure
      const P2 = pressureNum;
      const T1 = 293.15; // Standard temperature (20°C)
      const T2 = temperatureNum + 273.15; // Convert to Kelvin

      const fad = flowRateNum * (P1/P2) * (T2/T1) * (1 - humidityNum/100);
      setResult(fad.toFixed(2));
    } catch (err) {
      setError(err.message);
      setResult(null);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" component="h2" gutterBottom>
          Free Air Delivery (FAD) Calculator
        </Typography>
        
        <Typography variant="body1" color="text.secondary" paragraph>
          Calculate the Free Air Delivery of a compressor based on pressure, temperature, and humidity.
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Compressed Air Pressure (bar)"
              type="number"
              value={values.pressure}
              onChange={handleChange('pressure')}
              InputProps={{
                inputProps: { min: 0 }
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Temperature (°C)"
              type="number"
              value={values.temperature}
              onChange={handleChange('temperature')}
              InputProps={{
                inputProps: { min: -273.15 }
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Relative Humidity (%)"
              type="number"
              value={values.humidity}
              onChange={handleChange('humidity')}
              InputProps={{
                inputProps: { min: 0, max: 100 }
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Measured Flow Rate (l/min)"
              type="number"
              value={values.flowRate}
              onChange={handleChange('flowRate')}
              InputProps={{
                inputProps: { min: 0 }
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={calculateFAD}
              fullWidth
              size="large"
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : 'Calculate FAD'}
            </Button>
          </Grid>

          {error && (
            <Grid item xs={12}>
              <Alert severity="error">{error}</Alert>
            </Grid>
          )}

          {result !== null && (
            <Grid item xs={12}>
              <Box sx={{ mt: 2, p: 2, bgcolor: 'background.paper', borderRadius: 1 }}>
                <Typography variant="h6" gutterBottom>
                  Free Air Delivery:
                </Typography>
                <Typography variant="h4" color="primary">
                  {result} l/min
                </Typography>
              </Box>
            </Grid>
          )}

          <Grid item xs={12}>
            <Divider sx={{ my: 2 }} />
            <Typography variant="body2" color="text.secondary">
              Formula: FAD = Q * (P1/P2) * (T2/T1) * (1 - RH/100)
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Where:
            </Typography>
            <Typography variant="body2" color="text.secondary">
              FAD = Free Air Delivery (l/min)
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Q = Measured flow rate (l/min)
            </Typography>
            <Typography variant="body2" color="text.secondary">
              P1 = Atmospheric pressure (1.01325 bar)
            </Typography>
            <Typography variant="body2" color="text.secondary">
              P2 = Compressed air pressure (bar)
            </Typography>
            <Typography variant="body2" color="text.secondary">
              T1 = Atmospheric temperature (293.15 K)
            </Typography>
            <Typography variant="body2" color="text.secondary">
              T2 = Compressed air temperature (K)
            </Typography>
            <Typography variant="body2" color="text.secondary">
              RH = Relative humidity (%)
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default FAD; 