import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Grid,
  Box,
  Alert,
  CircularProgress,
  Divider,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';

const beltTypes = [
  { value: 'v_belt', label: 'V-Belt' },
  { value: 'flat_belt', label: 'Flat Belt' },
  { value: 'timing_belt', label: 'Timing Belt' },
];

function BeltSize() {
  const [values, setValues] = useState({
    beltType: 'v_belt',
    centerDistance: '',
    largePulleyDiameter: '',
    smallPulleyDiameter: '',
    speedRatio: '',
  });
  const [result, setResult] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleChange = (field) => (event) => {
    setValues(prev => ({
      ...prev,
      [field]: event.target.value
    }));
  };

  const calculateBeltSize = () => {
    const { beltType, centerDistance, largePulleyDiameter, smallPulleyDiameter, speedRatio } = values;
    
    if (!centerDistance || !largePulleyDiameter || !smallPulleyDiameter) {
      setError('Please fill in all required fields');
      return;
    }

    setLoading(true);
    setError('');

    try {
      const centerDistanceNum = parseFloat(centerDistance);
      const largePulleyDiameterNum = parseFloat(largePulleyDiameter);
      const smallPulleyDiameterNum = parseFloat(smallPulleyDiameter);
      const speedRatioNum = speedRatio ? parseFloat(speedRatio) : null;

      if (isNaN(centerDistanceNum) || isNaN(largePulleyDiameterNum) || isNaN(smallPulleyDiameterNum)) {
        throw new Error('Please enter valid numbers');
      }

      // Belt length calculation using the formula:
      // L = 2C + π(D1 + D2)/2 + (D1 - D2)²/(4C)
      // Where:
      // L = Belt length (mm)
      // C = Center distance (mm)
      // D1 = Large pulley diameter (mm)
      // D2 = Small pulley diameter (mm)

      const beltLength = 2 * centerDistanceNum + 
        Math.PI * (largePulleyDiameterNum + smallPulleyDiameterNum) / 2 + 
        Math.pow(largePulleyDiameterNum - smallPulleyDiameterNum, 2) / (4 * centerDistanceNum);

      // Calculate speed ratio if not provided
      const calculatedSpeedRatio = largePulleyDiameterNum / smallPulleyDiameterNum;

      // Calculate belt wrap angle
      const wrapAngle = 180 - 2 * Math.asin((largePulleyDiameterNum - smallPulleyDiameterNum) / (2 * centerDistanceNum)) * (180 / Math.PI);

      setResult({
        beltLength: beltLength.toFixed(2),
        speedRatio: calculatedSpeedRatio.toFixed(2),
        wrapAngle: wrapAngle.toFixed(1),
      });
    } catch (err) {
      setError(err.message);
      setResult(null);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" component="h2" gutterBottom>
          Belt Size Calculator
        </Typography>
        
        <Typography variant="body1" color="text.secondary" paragraph>
          Calculate belt length, speed ratio, and wrap angle for power transmission systems.
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel>Belt Type</InputLabel>
              <Select
                value={values.beltType}
                onChange={handleChange('beltType')}
                label="Belt Type"
              >
                {beltTypes.map((type) => (
                  <MenuItem key={type.value} value={type.value}>
                    {type.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Center Distance (mm)"
              type="number"
              value={values.centerDistance}
              onChange={handleChange('centerDistance')}
              InputProps={{
                inputProps: { min: 0 }
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Large Pulley Diameter (mm)"
              type="number"
              value={values.largePulleyDiameter}
              onChange={handleChange('largePulleyDiameter')}
              InputProps={{
                inputProps: { min: 0 }
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Small Pulley Diameter (mm)"
              type="number"
              value={values.smallPulleyDiameter}
              onChange={handleChange('smallPulleyDiameter')}
              InputProps={{
                inputProps: { min: 0 }
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Speed Ratio (optional)"
              type="number"
              value={values.speedRatio}
              onChange={handleChange('speedRatio')}
              InputProps={{
                inputProps: { min: 0 }
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={calculateBeltSize}
              fullWidth
              size="large"
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : 'Calculate Belt Size'}
            </Button>
          </Grid>

          {error && (
            <Grid item xs={12}>
              <Alert severity="error">{error}</Alert>
            </Grid>
          )}

          {result !== null && (
            <Grid item xs={12}>
              <Box sx={{ mt: 2, p: 2, bgcolor: 'background.paper', borderRadius: 1 }}>
                <Typography variant="h6" gutterBottom>
                  Results:
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <Typography variant="subtitle1">Belt Length:</Typography>
                    <Typography variant="h5" color="primary">
                      {result.beltLength} mm
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography variant="subtitle1">Speed Ratio:</Typography>
                    <Typography variant="h5" color="primary">
                      {result.speedRatio}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography variant="subtitle1">Wrap Angle:</Typography>
                    <Typography variant="h5" color="primary">
                      {result.wrapAngle}°
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          )}

          <Grid item xs={12}>
            <Divider sx={{ my: 2 }} />
            <Typography variant="body2" color="text.secondary">
              Formula: L = 2C + π(D1 + D2)/2 + (D1 - D2)²/(4C)
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Where:
            </Typography>
            <Typography variant="body2" color="text.secondary">
              L = Belt length (mm)
            </Typography>
            <Typography variant="body2" color="text.secondary">
              C = Center distance (mm)
            </Typography>
            <Typography variant="body2" color="text.secondary">
              D1 = Large pulley diameter (mm)
            </Typography>
            <Typography variant="body2" color="text.secondary">
              D2 = Small pulley diameter (mm)
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default BeltSize; 